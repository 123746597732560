import PropTypes from "prop-types";
import UserRolePropType from "helpers/prop-types/user-roles";

export default PropTypes.oneOfType([
  PropTypes.shape({}),
  PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    accountType: UserRolePropType.isRequired,
    status: PropTypes.number.isRequired,
    notificationCount: PropTypes.number,
    canBidOn: PropTypes.arrayOf(PropTypes.number),
    openDayReservedOn: PropTypes.arrayOf(PropTypes.number),
  }),
]);
