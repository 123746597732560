import axios from "axios";
import isString from "lodash/isString";
import Qs from "qs";
import config from "config";
import parseAxiosRejectError from "helpers/parse-axios-reject-error";

export default class Api {
  url = config.apiUrl;

  ongoingRequests = [];

  methods = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
  };

  get(resource, data = {}, token) {
    return this.request(resource, {
      data,
      method: this.methods.GET,
      token,
    });
  }

  post(resource, data, token) {
    return this.request(resource, {
      data,
      method: this.methods.POST,
      token,
    });
  }

  put(resource, data, token) {
    return this.request(resource, {
      data,
      method: this.methods.PUT,
      token,
    });
  }

  remove(resource, data, token) {
    return this.request(resource, {
      data,
      method: this.methods.DELETE,
      token,
    });
  }

  request(resource, request) {
    const tokenSource = axios.CancelToken.source();
    const { method, token } = request;
    return new Promise((resolve, reject) => {
      if (!isString(resource) || resource.length <= 0) {
        reject("Invalid resource path passed to the API");
      }

      if (resource.indexOf("/") !== 0) {
        reject("Missing / at the start of resource path.");
      }

      const requestConfig = {
        method,
        cancelToken: tokenSource.token,
        url: `${this.url}${resource}`,
        data: method === "GET" ? {} : request.data,
        params: method === "GET" ? request.data : {},
        paramsSerializer: (params) =>
          Qs.stringify(params, { arrayFormat: "brackets" }),
        responseType: "json",
        headers: {
          "Content-Type": `${
            method === "GET"
              ? "application/x-www-form-urlencoded"
              : "application/json"
          }`,
          Authorization: `Bearer ${token}`,
        },
      };

      this.cancel(resource, request.data, method);
      this.ongoingRequests.push({
        resource,
        data: request.data,
        method,
        token: tokenSource,
      });

      axios(requestConfig)
        .then((response) => {
          this.removeRequest(resource, request.data, method);
          resolve(response.data);
        })
        .catch((error) => reject(parseAxiosRejectError(error)));
    });
  }

  cancel(resource, data = {}, method) {
    const index = this.findRequestIndex(resource, data, method);
    if (index !== -1) {
      this.ongoingRequests[index].token.cancel();
      this.removeRequest(resource, data, method);
    }
  }

  cancelAll() {
    for (let i = 0; i < this.ongoingRequests.length; i += 1) {
      this.ongoingRequests[i].token.cancel();
    }
  }

  findRequestIndex(resource, data, method) {
    return this.ongoingRequests.findIndex(
      (request) =>
        request.resource === resource &&
        JSON.stringify(request.data) === JSON.stringify(data) &&
        request.method === method,
    );
  }

  removeRequest(resource, data, method) {
    const index = this.findRequestIndex(resource, data, method);

    if (index !== -1) {
      this.ongoingRequests.splice(index, 1);
    }
  }
}
