/* eslint-disable jsx-a11y/no-static-element-interactions */

import React from "react";
import PropTypes from "prop-types";
import Svg from "components/elements/svg";

export default class Modal extends React.Component {
  constructor(props) {
    super(props);
    // this.bodyScrollTop = false;
    this.state = {
      stateClass: "hidden",
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    // Open only if is closed
    if (props.isOpen && !this.props.isOpen) {
      this.setState({ stateClass: "opening" });
      setTimeout(() => {
        this.setState({ stateClass: "open" });
        setTimeout(() => {
          this.props.onOpened();
        }, 450);
      }, 50);
      // Close only if is opened
    } else if (!props.isOpen && this.props.isOpen) {
      this.setState({ stateClass: "hiding" });
      setTimeout(() => {
        this.setState({ stateClass: "hidden" });
        if (typeof this.props.onClosed === "function") {
          this.props.onClosed();
        }
      }, 500);
    }
  }

  toggleModal() {
    if (this.props.dismissible && typeof this.props.onClose === "function") {
      this.props.onClose();
    }
  }

  render() {
    const modalClass = ["modal", this.props.className, this.state.stateClass];
    return (
      <div
        style={{ cursor: this.props.dismissible ? "pointer" : "default" }}
        className={modalClass.join(" ")}
        onClick={() => this.toggleModal()}
      >
        <div className="modal__frame" onClick={(e) => e.stopPropagation()}>
          <div className="modal__header">
            <h4 className="title">
              <span className="title-inner">{this.props.title}</span>
            </h4>
            {this.props.dismissible && (
              <button
                type="button"
                className="close-button"
                onClick={() => this.toggleModal()}
              >
                <Svg className="icon" src="close-icon" />
              </button>
            )}
            {this.props.icon && (
              <div className="icon-container">
                <Svg className="icon" src={this.props.icon} />
              </div>
            )}
          </div>
          <div className="modal__body">{this.props.children}</div>
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  icon: PropTypes.string,
  dismissible: PropTypes.bool,
  isOpen: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  onClosed: PropTypes.func,
  onOpened: PropTypes.func,
};

Modal.defaultProps = {
  title: "Modal title",
  className: "",
  icon: "",
  dismissible: true,
  isOpen: false,
  onClose: () => null,
  onClosed: () => null,
  onOpened: () => null,
};
