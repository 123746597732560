import React from "react";
import clsx from "clsx";
import { trim } from "lodash";
import NextLink from "next/link";
import { useRouter } from "next/router";

const Link = ({
  to,
  title,
  children,
  className,
  ...props
}: {
  to: string;
  title?: string;
  children?: React.ReactNode;
  className?: string;
}) => {
  const { pathname } = useRouter();
  const isActive = pathname.includes(to.toString());

  if (
    to.startsWith("http://") ||
    to.startsWith("https://") ||
    to.startsWith("//")
  ) {
    return (
      <a href={to} target="_blank" rel="noreferrer" className={className}>
        {children}
      </a>
    );
  }

  return (
    <NextLink
      href={`/${trim(to, "/")}`}
      title={title}
      className={clsx(className, {
        active: isActive,
      })}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </NextLink>
  );
};

export default Link;
