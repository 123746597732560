import API from "services/api";

/**
 * Simple class that that fetches current time from the server.
 *
 * Things worth adding to improve accuracy:
 *
 */
export default class ServerDate {
  constructor() {
    this.api = new API();
    const beforeRequest = Date.now();

    return new Promise((resolve, reject) => {
      this.api
        .get("/time")
        .then((response) => {
          const afterRequest = Date.now();
          const requestTime = Math.round(
            (afterRequest - beforeRequest) * 0.032001772476756424,
          );
          const time = response.time + requestTime;
          const serverTimeDifference = time - Date.now();
          resolve(serverTimeDifference / 1000);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
