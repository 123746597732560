/* eslint-disable react/no-danger */
import React from "react";
import Head from "next/head";
import Router from "next/router";
import PropTypes from "prop-types";
import Pusher from "pusher-js";
import { connect } from "react-redux";
import {
  setAppLoaded,
  setAppLoading,
  setCurrentUrl,
  setServerTimeDifference,
} from "actions/app";
import { setCurrentUser, setAuthTokens } from "actions/auth";
import AuctionEndNotification from "components/core/auction-end-notification";
import ConnectivityNotification from "components/core/connectivity-notification";
import CookieBox from "components/core/cookie-box";
import Footer from "components/core/footer";
import MobileMenu from "components/core/mobile-menu";
import MobileMenuOverlay from "components/core/mobile-menu-overlay";
import OutbidNotification from "components/core/outbid-notification";
import SiteNoticeModal from "components/core/site-notice-modal";
import BackToTop from "components/elements/back-to-top";
import GoogleMapsScript from "components/elements/google-maps-script";
import Preloader from "components/elements/preloader";
import currentUserPropType from "helpers/prop-types/current-user";
import ServerDate from "helpers/server-date";
import { USER_ACCOUNT_CLOSED, USER_CREATED } from "helpers/user-statuses";
import AuthService from "services/auth";

class Layout extends React.Component {
  UNSAFE_componentWillMount() {
    if (process.browser) {
      window.pusher =
        window.pusher ||
        new Pusher(process.env.NEXT_PUBLIC_PUSHER_KEY, {
          cluster: "eu",
          encrypted: true,
          pongTimeout: 1000,
        });
    }
  }

  componentDidMount() {
    this.fetchServerTime();
    Router.onRouteChangeStart = () => {
      this.props.setAppLoading();
    };
    Router.onRouteChangeError = () => {
      this.props.setAppLoaded();
    };
    Router.onRouteChangeComplete = (url) => {
      this.props.setAppLoaded();
      this.props.setCurrentUrl(url);
    };

    const { user } = this.props;

    if (user.id) {
      if ([USER_ACCOUNT_CLOSED, USER_CREATED].includes(user.status)) {
        this.logOut();
      } else {
        const { pusher } = window;
        this.socket = pusher.subscribe("users-channel");
        this.socket.bind(`current-user.update.${user.id}`, (event) => {
          if ([USER_ACCOUNT_CLOSED, USER_CREATED].includes(event.data.status)) {
            this.logOut();
          } else {
            this.props.setCurrentUser({ ...user, ...event.data });
          }
        });
      }
    }

    this.props.setAppLoaded();
  }

  UNSAFE_componentWillReceiveProps({ user }) {
    if ([USER_ACCOUNT_CLOSED, USER_CREATED].includes(user.status)) {
      this.logOut();
    }
  }

  logOut() {
    this.props.setAppLoading();
    AuthService.logOut()
      .then((newTokens) => {
        this.props.setAuthTokens(newTokens);
        window.location.href = "/sign-in";
      })
      .catch(() => {
        window.location.reload();
      });
  }

  fetchServerTime() {
    new ServerDate().then((diff) => this.props.setServerTimeDifference(diff));
  }

  render() {
    const { title, className, children, loading } = this.props;

    const menuOpen =
      process.browser && window.innerWidth > 1024 ? false : this.props.menuOpen;

    const a1wScript = `
      var cid = 4340;
      (function() { window.a1WebStatsObj = 'a1w'; window.a1w = window.a1w || function(){ (window.a1w.q = window.ga.q || []).push(arguments) }, window.a1w.l = 1 * new Date(); var a = document.createElement('script'); var m = document.getElementsByTagName('script')[0]; console.log(a, m); a.async = 1; a.src = "https://api1.websuccess-data.com/tracker.js"; m.parentNode.insertBefore(a,m) })()
    `;
    const isProduction = process.env.NODE_ENV === "production";

    return (
      <div
        className={`layout${loading ? " loading" : ""}${
          menuOpen ? " menu-open" : ""
        }`}
      >
        <Head>
          <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link
            rel="icon"
            href="/static/images/favicon.ico"
            type="image/x-icon"
          />
          <meta
            property="og:image"
            content="https://hammerpricehomes.co.uk/static/images/hammer_price_logo_sq.png"
          />
          <title>{title ? `${title} - ` : ""}Hammer Price Homes</title>
          {isProduction && (
            <script src="https://www.googletagmanager.com/gtag/js?id=UA-116198058-1" />
          )}
          {isProduction && (
            <script
              dangerouslySetInnerHTML={{
                __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'UA-116198058-1');`,
              }}
            />
          )}
        </Head>
        <div className="wrap">
          <MobileMenuOverlay />
          <div className={`page ${className}`}>{children}</div>
          <Footer />
        </div>
        <OutbidNotification />
        <AuctionEndNotification />
        <ConnectivityNotification />
        <SiteNoticeModal />
        <MobileMenu />
        <Preloader show={loading} />
        <CookieBox />
        <BackToTop />
        <GoogleMapsScript />
        {isProduction && (
          <script dangerouslySetInnerHTML={{ __html: a1wScript }} />
        )}
      </div>
    );
  }
}

Layout.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  setAppLoaded: PropTypes.func,
  setAppLoading: PropTypes.func,
  setCurrentUrl: PropTypes.func,
  setAuthTokens: PropTypes.func,
  loading: PropTypes.bool,
  menuOpen: PropTypes.bool,
  setServerTimeDifference: PropTypes.func,
  setCurrentUser: PropTypes.func,
  user: currentUserPropType,
};

Layout.defaultProps = {
  title: "",
  className: "",
  children: null,
  setAppLoaded: () => null,
  setAppLoading: () => null,
  setCurrentUser: () => null,
  setAuthTokens: () => null,
  setCurrentUrl: () => null,
  loading: true,
  menuOpen: false,
  setServerTimeDifference: () => null,
  user: {},
};

const mapStateToProps = (state) => ({
  loading: state.app.loading,
  menuOpen: state.app.menuOpen,
  user: state.auth.currentUser,
});

export default connect(mapStateToProps, {
  setAppLoaded,
  setAppLoading,
  setCurrentUrl,
  setServerTimeDifference,
  setCurrentUser,
  setAuthTokens,
})(Layout);
