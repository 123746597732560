import React from "react";
import Modal from "components/elements/modal";
import Svg from "../elements/svg";

class ConnectivityNotification extends React.Component {
  state = {
    opened: false,
  };

  componentDidMount() {
    window.addEventListener("offline", () => {
      this.open();
    });

    window.pusher.connection.bind("state_change", (states) => {
      if (states.current === "unavailable") {
        this.open();
      }
    });
  }

  open() {
    if (!this.state.opened) {
      this.setState({ opened: true });
    }
  }

  refresh() {
    window.location.reload();
  }

  render() {
    const { opened } = this.state;

    return (
      <Modal
        key="connectivityModal"
        isOpen={opened}
        title="Connection lost"
        icon="internet"
        dismissible={false}
      >
        <p>
          We have detected a problem with your internet connection. Please check
          you are connected to WIFI or 3/4G and reload this page via the
          ‘Refresh’ button. If the problem persists, this modal will be
          displayed again.
        </p>
        <div className="text-center" style={{ marginTop: "2rem" }}>
          <button
            type="button"
            className="btn btn-secondary with-arrow"
            onClick={() => this.refresh()}
          >
            Refresh
            <Svg src="spin" className="arrow" />
          </button>
        </div>
      </Modal>
    );
  }
}

export default ConnectivityNotification;
