import React from "react";

const GoogleMapsScript = () => (
  <script
    type="text/javascript"
    async
    defer
    src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBzwXkJaVfR5lIo2zvPZRxmpn0WvSxvFxQ&libraries=places&language=en"
  />
);

export default GoogleMapsScript;
