import React from "react";
import PropTypes from "prop-types";
import SVG from "components/elements/svg";

const InfoSection = ({ theme, icon, title, className, children }) => (
  <section className={`info-section ${theme} ${className}`}>
    <div className="container">
      <div className="icon-in-circle">
        <SVG src={icon} className="icon" />
      </div>

      <h1 className="section-heading">{title}</h1>

      {children}
    </div>
  </section>
);

InfoSection.propTypes = {
  theme: PropTypes.oneOf(["orange", "red"]),
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
};

InfoSection.defaultProps = {
  theme: "orange",
  className: "",
  children: null,
};

export default InfoSection;
