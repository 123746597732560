import React from "react";
import SVG from "components/elements/svg";

const HeaderBanner = ({ title, background, icon }: HeaderBannerProps) => (
  <div
    className="header__banner"
    style={background ? { backgroundImage: `url(${background})` } : {}}
  >
    <div className="container">
      {icon && <SVG src={icon} className="header__banner__icon" />}
      <h1 className="header__banner__title">{title}</h1>
    </div>
  </div>
);

interface HeaderBannerProps {
  title: string;
  background?: string;
  icon?: string;
}

export default HeaderBanner;
