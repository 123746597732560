import React from "react";
import Cookies from "js-cookie";
import Modal from "components/elements/modal";

const COOKIE_KEY = "site-notice";

class SiteNoticeModal extends React.Component {
  state = {
    opened: false,
    version: "",
  };

  componentDidMount() {
    if (Cookies.get(COOKIE_KEY) !== this.state.version) {
      // this.open();
    }
  }

  open() {
    this.setState({ opened: true });
  }

  close() {
    this.setState({ opened: false }, () => {
      Cookies.set(COOKIE_KEY, this.state.version, { expires: 1 / 24 });
    });
  }

  render() {
    const { opened } = this.state;

    return (
      <Modal
        key="siteNoticeModal"
        isOpen={opened}
        title=""
        icon="bell-icon"
        onClose={() => this.close()}
        dismissible
      >
        <div className="text-content" />

        <div className="text-center" style={{ marginTop: "3rem" }}>
          <button
            type="button"
            className="btn btn-secondary with-arrow"
            onClick={() => this.close()}
          >
            Close
          </button>
        </div>
      </Modal>
    );
  }
}

export default SiteNoticeModal;
