import React from "react";
import PropTypes from "prop-types";
import sanitizer from "sanitize-html";

const sanitize = (content) =>
  sanitizer(content, {
    allowedTags: [
      "strong",
      "span",
      "a",
      "ul",
      "ol",
      "li",
      "p",
      "blockquote",
      "del",
    ],
    allowedAttributes: {
      a: ["href", "title", "target"],
      "*": ["style", "data-*"],
    },
  });

const TextContent = ({ content, children, className }) => {
  if (content) {
    /* eslint-disable react/no-danger */
    return (
      <div
        className={`text-content ${className}`}
        dangerouslySetInnerHTML={{ __html: sanitize(content) }}
      />
    );
    /* eslint-enable */
  }
  if (children) {
    return <div className={`text-content ${className}`}>{children}</div>;
  }

  return null;
};

TextContent.propTypes = {
  content: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};

TextContent.defaultProps = {
  content: null,
  children: null,
  className: "",
};

export default TextContent;
