import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAppLoading, toggleMenu } from "actions/app";
import { setAuthTokens } from "actions/auth";
import Link from "components/elements/link";
import Svg from "components/elements/svg";
import { mainMenu } from "helpers/menus";
import AuthService from "services/auth";
import { AppState } from "store";

type HeaderProps = {
  children: React.ReactNode;
  background?: string;
};

const Header: React.FC<HeaderProps> = ({ children, background }) => {
  const dispatch = useDispatch();
  const user = useSelector((state: AppState) => state.auth.currentUser);
  const menu = mainMenu.filter((item) => item.url !== "sell-property");

  const onLogOut = () => {
    dispatch(setAppLoading());
    AuthService.logOut()
      .then((newTokens) => {
        dispatch(setAuthTokens(newTokens));
        window.location.href = "/sign-in";
      })
      .catch(() => {});
  };

  const isLoggedIn = !!user.id;

  return (
    <header
      className="header"
      style={background ? { backgroundImage: `url(${background})` } : {}}
    >
      <div className="header__inner">
        <div className="top-bar">
          <Link to="/" title="Hammer Price Homes" className="logo">
            <Svg
              className="desktop-logo logo-icon"
              src="logo"
              title="Hammer Price Homes logo"
            />
            <Svg
              className="mobile-logo logo-icon"
              src="logo-mobile"
              title="Hammer Price Homes logo"
            />
          </Link>
          <nav className="main-menu">
            <ul className="header__menu">
              {menu.map((menuItem) => (
                <li className="menu-item" key={menuItem.label}>
                  <Link to={menuItem.url}>{menuItem.label}</Link>
                  {menuItem.sub && (
                    <ul className="menu-item__sub">
                      {menuItem.sub.map((subMenuItem) => (
                        <li className="sub-menu-item" key={subMenuItem.label}>
                          <Link to={subMenuItem.url}>{subMenuItem.label}</Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
            {!isLoggedIn && (
              <ul className="header__menu--account">
                <li className="menu-item">
                  <Link to="sign-in">Sign in</Link>
                </li>
                <li className="menu-item">
                  <Link to="register">Register</Link>
                </li>
              </ul>
            )}
            {isLoggedIn && (
              <ul className="header__menu--account">
                <li className="menu-item">
                  <Link to="my-account/notifications">
                    My account ({user.notificationCount || 0})
                  </Link>
                </li>
                <li className="menu-item">
                  <button type="button" onClick={onLogOut} className="log-out">
                    Log out
                  </button>
                </li>
              </ul>
            )}
          </nav>
          <button
            type="button"
            className="menu-toggle"
            onClick={() => dispatch(toggleMenu())}
          >
            <span className="menu-toggle__inner" />
          </button>
        </div>
        <div className="header__content">{children}</div>
      </div>
    </header>
  );
};

export default Header;
