import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Link from "components/elements/link";
import Modal from "components/elements/modal";
import Svg from "components/elements/svg";

class OutbidNotification extends React.Component {
  constructor(props) {
    super(props);
    let canNotify = false;

    // only ask for permission to send notifications if a user is logged in
    // we won't send them to a logged out users anyway
    if (props.user.id && process.browser) {
      const { Notification } = window;
      if (Notification && Notification !== undefined) {
        if (Notification.permission === "granted") {
          canNotify = true;
        } else if (Notification.permission !== "denied") {
          Notification.requestPermission((permission) => {
            // If the user accepts, let's create a notification
            if (permission === "granted") {
              canNotify = true;
            }
          });
        }
      }
    }
    this.state = {
      canNotify,
      openModals: [],
      properties: [],
    };
  }

  componentDidMount() {
    const { user } = this.props;
    if (user.id) {
      const { pusher } = window;
      this.socket = pusher.subscribe("users-channel");
      this.socket.bind(`user.outbid.${user.id}`, (event) => {
        this.notify(event.data);
      });
    }
  }

  componentWillUnmount() {
    if (this.socket) {
      this.socket.unbind();
    }
  }

  notify(property) {
    if (this.state.canNotify) {
      const notification = new window.Notification("Hammer Price Homes", {
        body: `You have been outbid on ${property.title}`,
        icon: "/static/images/logo-symbol.png",
      });
      notification.addEventListener("click", () => {
        window.location.href = `/properties/${property.slug}/`;
      });
    }
    if (process.browser && !document.hidden) {
      this.setState({ properties: [property] }, () => {
        this.setState({ openModals: [property.ID] });
      });
    }
  }

  closeModal(ID) {
    const index = this.state.openModals.indexOf(ID);
    if (index >= 0) {
      const { openModals } = this.state;
      const properties = this.state.properties.filter(
        (property) => property.ID !== ID,
      );
      openModals.splice(index, 1);

      // Close the modal, then remove property from the list
      this.setState({ openModals }, () => {
        setTimeout(() => this.setState({ properties }), 550);
      });
    }
  }

  render() {
    return (
      <div className="outbid-notification">
        {this.state.properties.map((property) => (
          <Modal
            key={property.ID}
            isOpen={this.state.openModals.includes(property.ID)}
            onClose={() => this.closeModal(property.ID)}
            title="Outbid alert"
            icon="bell-icon"
          >
            <p>You have been outbid on the following auction:</p>
            <p>
              <Link to={`properties/${property.slug}`}>{property.title}</Link>
            </p>
            <div className="text-center outbid-notification__actions">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => this.closeModal(property.ID)}
              >
                OK
              </button>
              {!this.props.url.includes(`/properties/${property.slug}`) && (
                <Link
                  className="btn btn-primary with-arrow"
                  to={`properties/${property.slug}`}
                >
                  View property
                  <Svg src="arrow-right" className="arrow" />
                </Link>
              )}
            </div>
          </Modal>
        ))}
      </div>
    );
  }
}

OutbidNotification.defaultProps = {
  url: "",
};

OutbidNotification.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  url: PropTypes.string,
};

const mapStateToProps = (state) => ({
  user: state.auth.currentUser,
  url: state.app.url,
});

export default connect(mapStateToProps)(OutbidNotification);
