import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import SVG from "components/elements/svg";

const COOKIE_KEY = "cookies-acceptance";

const CookieBox = () => {
  const [state, setState] = useState(true);

  const show = () => {
    if (!Cookies.get(COOKIE_KEY)) {
      setState(false);
    }
  };

  const close = () => {
    setState(true);

    Cookies.set(COOKIE_KEY, "true", { expires: 365 });
  };

  useEffect(() => {
    show();
  }, []);

  return (
    <div className={`cookie-box ${state ? "hidden" : ""}`}>
      <div className="cookie-box__inner container">
        <div className="cookie-box__content">
          <div className="cookie-box__icon">
            <SVG src="cookie-icon" />
          </div>
          <div className="cookie-box__content__inner">
            <p className="cookie-box__title">Cookies policy</p>
            <div className="cookie-box__desc">
              Our website uses cookies to analyse how the site is used and to
              ensure your experience is consistent between visits.&nbsp;
              <a
                href="/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more &raquo;
              </a>
            </div>
          </div>
        </div>
        <div className="cookie-box__buttons">
          <button
            className="cookie-box__button"
            onClick={() => close()}
            type="button"
          >
            <span>Accept</span>
            <SVG src="cookie-accept" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieBox;
