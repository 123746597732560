import React from "react";
import PropTypes from "prop-types";

const PhoneLink = ({ number, children, prefix }) => (
  <a href={`tel:00${prefix}${number.replace(/\D*/, "")}`}>
    {children}
    <span className="number">{number}</span>
  </a>
);

PhoneLink.propTypes = {
  number: PropTypes.string,
  children: PropTypes.node,
  prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

PhoneLink.defaultProps = {
  number: "020 3814 1888",
  prefix: "44",
  children: null,
};

export default PhoneLink;
