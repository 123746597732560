import {
  APP_LOADED,
  APP_LOADING,
  TOGGLE_MENU,
  SET_CURRENT_URL,
  SET_SERVER_TIME_DIFFERENCE,
} from "actions";

export const setAppLoaded = () => ({ type: APP_LOADED });
export const setAppLoading = () => ({ type: APP_LOADING });
export const toggleMenu = () => ({ type: TOGGLE_MENU });
export const setCurrentUrl = (url: string) => ({ type: SET_CURRENT_URL, url });
export const setServerTimeDifference = (difference: number) => ({
  type: SET_SERVER_TIME_DIFFERENCE,
  difference,
});
