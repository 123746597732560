import React from "react";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FormattedPrice from "components/elements/formatted-price";
import Link from "components/elements/link";
import Modal from "components/elements/modal";
import Svg from "components/elements/svg";
import TextContent from "components/elements/text-content";

class AuctionEndNotification extends React.Component {
  constructor(props) {
    super(props);
    let canNotify = false;

    // only ask for permission to send notifications if a user is logged in
    // we won't send them to a logged out users anyway
    if (props.user.id && process.browser) {
      const { Notification } = window;
      if (Notification && Notification !== undefined) {
        if (Notification.permission === "granted") {
          canNotify = true;
        } else if (Notification.permission !== "denied") {
          Notification.requestPermission((permission) => {
            // If the user accepts, let's create a notification
            if (permission === "granted") {
              canNotify = true;
            }
          });
        }
      }
    }
    this.state = {
      canNotify,
      result: {},
    };
  }

  componentDidMount() {
    const { user } = this.props;
    if (user.id) {
      const { pusher } = window;
      this.socket = pusher.subscribe("users-channel");
      this.socket.bind(`user.auction-ended.${user.id}`, (event) => {
        this.notify(event.data);
      });
    }
  }

  componentWillUnmount() {
    if (this.socket) {
      this.socket.unbind();
    }
  }

  notify(result) {
    if (this.state.canNotify) {
      let body = "";
      if (result.sold) {
        body = result.winner
          ? `You have won the auction of ${result.title}`
          : `The auction of ${result.title} has ended`;
      } else {
        body = result.winner
          ? `Auction ended! Thank you for your interest in ${result.title}. The reserve price for this property was not met.`
          : `Auction ended! Thank you for your interest in ${result.title}`;
      }
      const notification = new window.Notification("Hammer Price Homes", {
        body,
        icon: "/static/images/logo-symbol.png",
      });
      notification.addEventListener("click", () => {
        window.location.href = `/properties/${result.slug}/`;
      });
    }
    this.setState({ result });
  }

  render() {
    const { result } = this.state;
    const onClose = () => this.setState({ result: {} });
    let title = "";
    if (result.sold && result.winner) {
      title = "Congratulations! you have won the auction!";
    }
    if (result.sold && !result.winner) {
      title = "Auction ended!";
    }
    if (!result.sold) {
      title = "Auction ended!";
    }
    return (
      <Modal
        className="bidding-result-modal"
        title={title}
        onClose={onClose}
        isOpen={Object.keys(result).length > 0}
        icon="bell-icon"
      >
        {Object.keys(result).length > 0 && (
          <div className="bidding-result-modal__content">
            {!result.sold && (
              <TextContent>
                <p>
                  Thank you for your interest in{" "}
                  <a href={`/properties/${result.slug}`}>{result.title}</a>
                </p>
                {result.winner && (
                  <p>
                    The reserve price for this property was not met. As you were
                    the highest bidder at{" "}
                    <FormattedPrice price={result.currentPrice} />, one of our
                    team will call you shortly to discuss the next step in the
                    process.
                  </p>
                )}
                {!result.winner && (
                  <p>
                    The auction has now ended and the highest bid is:{" "}
                    <FormattedPrice price={result.currentPrice} />.
                  </p>
                )}
              </TextContent>
            )}
            {result.sold && result.winner && result.thumbnail && (
              <div className="img-container">
                <img
                  src={result.thumbnail}
                  alt={result.title}
                  className="thumbnail"
                />
              </div>
            )}
            {result.sold && !result.winner && (
              <TextContent>
                <p>
                  The auction has now ended and the highest bid is:{" "}
                  <FormattedPrice price={result.currentPrice} />.
                </p>
              </TextContent>
            )}
            {result.sold && result.winner && (
              <div className="data-container">
                <div className="title">{result.title}</div>
                <Svg src="shopping-cart-ok" className="cart-icon" />
                <div className="price-text">
                  Final price: <FormattedPrice price={result.currentPrice} />
                  <div className="bids-count">{`${
                    result.numberOfBids
                  } ${pluralize("bid", result.numberOfBids)}`}</div>
                </div>
              </div>
            )}
          </div>
        )}
        {result.sold && result.winner && Object.keys(result).length > 0 && (
          <div className="bidding-result-modal__actions col-md-12">
            {result.slug && (
              <Link
                className="btn btn-primary with-arrow"
                to={`property-won/${result.slug}`}
              >
                <span>Proceed to the next step</span>
                <Svg className="arrow" src="arrow-right" />
              </Link>
            )}
          </div>
        )}
      </Modal>
    );
  }
}

AuctionEndNotification.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.currentUser,
  url: state.app.url,
});

export default connect(mapStateToProps)(AuctionEndNotification);
