import React from "react";
import PropTypes from "prop-types";
import withAuth from "components/core/with-auth";
import Link from "components/elements/link";
import SVG from "components/elements/svg";
import UserPropType from "helpers/prop-types/current-user";
import { USER_ADDRESS_VERIFIED } from "helpers/user-statuses";

const ALL_TABS = [
  {
    icon: "chat",
    name: "Notifications",
    link: "my-account/notifications",
    visible: ["seller", "buyer", "admin"],
    verified: false,
  },
  {
    icon: "user",
    name: "Profile",
    link: "my-account",
    visible: ["seller", "buyer", "admin"],
    verified: false,
  },
  {
    icon: "time",
    name: "Bidding",
    link: "my-account/bidding",
    visible: ["buyer"],
    verified: true,
  },
  {
    icon: "shopping-cart-ok",
    name: "Won",
    link: "my-account/won",
    visible: ["buyer"],
    verified: true,
  },
  {
    icon: "shopping-cart",
    name: "Selling",
    link: "my-account/selling",
    visible: ["seller"],
    verified: true,
  },
  {
    icon: "time",
    name: "Pending",
    link: "my-account/pending",
    visible: ["seller"],
    verified: true,
  },
  {
    icon: "shopping-cart-ok",
    name: "Sold",
    link: "my-account/sold",
    visible: ["seller"],
    verified: true,
  },
  {
    icon: "shopping-cart-fail",
    name: "Not sold",
    link: "my-account/not-sold",
    visible: ["seller"],
    verified: true,
  },
  {
    icon: "shopping-cart-fail",
    name: "Not won",
    link: "my-account/not-won",
    visible: ["buyer"],
    verified: true,
  },
  {
    icon: "calendar-2",
    name: "Open days",
    link: "my-account/open-days",
    visible: ["buyer", "seller"],
    verified: true,
  },
];

export const USER_TABS = (user) => {
  if (!user) {
    return [];
  }

  const verified = user.status > USER_ADDRESS_VERIFIED;

  return ALL_TABS.filter((tab) =>
    tab.visible.includes(user.accountType),
  ).filter((tab) => {
    if (!verified) {
      return tab.verified === verified;
    }
    return true;
  });
};

const Tabs = ({ active, user }) => (
  <section className="my-account__tabs">
    <div className="container">
      <ul className="nav nav-tabs">
        {USER_TABS(user).map((item) => (
          <li
            role="presentation"
            className={`${active === item.link ? "active" : ""}`}
            key={item.link}
          >
            <Link to={item.link}>
              <SVG src={item.icon} className="icon" />
              <span>{item.name}</span>
              {item.link === "my-account/notifications" && (
                <span> ({user.notificationCount})</span>
              )}
            </Link>
          </li>
        ))}
        ;
      </ul>
    </div>
  </section>
);

Tabs.propTypes = {
  active: PropTypes.string.isRequired,
  user: UserPropType.isRequired,
};

export default withAuth(Tabs);
