import axios from "axios";
import Cookies from "cookies";
import { addSeconds, getUnixTime } from "date-fns";
import jsCookie from "js-cookie";
import Api from "services/api";
import parseAxiosRejectError from "../helpers/parse-axios-reject-error";

const config = require("config");

const getCookieDomain = (req = null) => {
  const domain =
    typeof window === "undefined" ? req.headers.host : window.location.hostname;

  if (domain === "localhost") {
    return "localhost";
  }

  return `.${domain.replace("www.", "")}`;
};

const request = {
  method: "POST",
  responseType: "json",
  headers: { "Content-Type": "application/json" },
  data: {},
};

const AuthService = {
  getNewTokens(refreshToken = null, req = null) {
    return new Promise((resolve, reject) => {
      const requestConfig = {
        ...request,
        url: `${config.selfUrl}/api/auth`,
        data: { refreshToken },
      };
      if (req && req.headers["x-forwarded-for"]) {
        requestConfig.headers = {
          ...requestConfig.headers,
          "X-Forwarded-For": req.headers["x-forwarded-for"],
        };
      }
      axios(requestConfig)
        .then((response) =>
          resolve({
            token: response.data.access_token,
            refreshToken: response.data.refresh_token
              ? response.data.refresh_token
              : "",
            tokenExpiration: response.data.expires_in,
          }),
        )
        .catch((error) => reject(parseAxiosRejectError(error)));
    });
  },

  getTokens(req = null, res = null) {
    return new Promise((resolve, reject) => {
      let cookies = {};

      if (req) {
        cookies = req.cookies;
      } else if (typeof window !== "undefined") {
        cookies = jsCookie.get();
      }

      const tokens = {
        token: cookies.token || "",
        refreshToken: cookies.refreshToken || "",
        tokenExpiration: Number(cookies.tokenExpiration) || 0,
      };

      if (tokens.token) {
        resolve(tokens);
      } else {
        AuthService.getNewTokens(tokens.refreshToken, req)
          .then((newTokens) => {
            AuthService.setTokensCookies(newTokens, res, req);
            resolve(newTokens);
          })
          .catch((error) => reject(error));
      }
    });
  },

  logIn(username, password) {
    return new Promise((resolve, reject) => {
      if (!username || !password) {
        reject(new Error("Missing username or password!"));
      }
      const requestConfig = {
        ...request,
        url: `${config.selfUrl}/api/login`,
        data: { username, password },
      };
      axios(requestConfig)
        .then((response) => {
          if (response.data.accountClosed) {
            reject(
              new Error(
                'Your account has been closed, please call us on <a href="02038141888">020 3814 1888</a> for details.',
              ),
            );
          } else if (response.data.mailVerified === false) {
            reject(
              new Error(
                "Your email isn't verified. Please click on the activation link that has been sent to you.",
              ),
            );
          } else if (!!response.data.lockedOut && response.data.lockedOut) {
            reject(
              new Error(
                'You have been locked out of your account. To regain access, please call us on <a href="004402038141888">020 3814 1888</a>',
              ),
            );
          } else {
            const tokens = {
              token: response.data.access_token,
              refreshToken: response.data.refresh_token,
              tokenExpiration: response.data.expires_in,
            };
            resolve(tokens);
          }
        })
        .catch((error) => {
          console.error(error);
          let errorMessage = "";
          let status = 500;
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (error.response.status) {
              status = error.response.status;
            }
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest
            status = error.request.status;
          }
          switch (status) {
            case 400: {
              errorMessage = error.response.data.message;
              break;
            }
            case 401: {
              errorMessage = error.response.data.message;
              break;
            }
            case 500: {
              errorMessage = "Something went wrong on our end.";
              break;
            }
            default: {
              break;
            }
          }
          reject({
            code: status,
            message: errorMessage,
            hint: error.response.data.hint,
          });
        });
    });
  },

  getCurrentUser(token) {
    const api = new Api();
    return new Promise((resolve, reject) => {
      api
        .get("/users/current", {}, token)
        .then((response) => resolve(response.data))
        .catch((error) => {
          let errorMessage = error.message;
          switch (error.code) {
            case 401: {
              errorMessage = "Invalid user data provided.";
              break;
            }
            case 404: {
              errorMessage = "You are no longer logged in.";
              break;
            }
            case 500: {
              errorMessage = "Something went wrong on our end.";
              break;
            }
            default: {
              break;
            }
          }
          reject(errorMessage);
        });
    });
  },

  isLoggedIn(req = null) {
    let cookies = {};
    if (req) {
      cookies = req.cookies;
    } else if (process.browser) {
      cookies = jsCookie.get();
    }
    return !!cookies.refreshToken;
  },

  logOut(res = null, req = null) {
    return new Promise((resolve, reject) => {
      try {
        AuthService.clearTokensCookies(res, req);
        AuthService.getNewTokens()
          .then((tokens) => {
            AuthService.setTokensCookies(tokens, res, req);
            resolve(tokens);
          })
          .catch((error) => {
            if (error.code === 503) {
              reject({
                ...error,
                message:
                  "Due to 5 incorrect login attempts, a block has been placed on your account. Please try again in 1 hour.",
              });
            } else {
              reject(error);
            }
          });
      } catch (error) {
        reject(parseAxiosRejectError(error));
      }
    });
  },

  setTokensCookies(tokens, res = null, req = null) {
    const expires = addSeconds(new Date(), tokens.tokenExpiration);
    const domain = getCookieDomain(req, res);
    if (res) {
      const cookies = new Cookies(req, res);
      if (tokens.refreshToken) {
        cookies.set("refreshToken", tokens.refreshToken, {
          httpOnly: false,
          expires,
          domain,
        });
      } else {
        cookies.set("refreshToken");
      }
      cookies.set("token", tokens.token, {
        httpOnly: false,
        expires,
        domain,
      });
      cookies.set("tokenExpiration", getUnixTime(expires), {
        httpOnly: false,
        expires,
        domain,
      });
    } else {
      if (tokens.refreshToken) {
        jsCookie.set("refreshToken", tokens.refreshToken, {
          expires,
          domain,
        });
      } else {
        jsCookie.remove("refreshToken");
      }
      jsCookie.set("token", tokens.token, {
        expires,
        domain,
      });
      jsCookie.set("tokenExpiration", getUnixTime(expires), {
        expires,
        domain,
      });
    }
  },

  getTokensCookies(req) {
    const tokens = {
      token: "",
      refreshToken: "",
      tokenExpiration: 0,
    };
    let cookies = {};
    if (req) {
      cookies = req.cookies;
    } else if (typeof window !== "undefined") {
      cookies = jsCookie.get();
    }

    if (cookies.token) {
      tokens.token = cookies.token;
    }

    if (cookies.refreshToken) {
      tokens.refreshToken = cookies.refreshToken;
    }

    if (cookies.tokenExpiration) {
      tokens.tokenExpiration = cookies.tokenExpiration;
    }

    return tokens;
  },

  clearTokensCookies(res = null, req = null) {
    const domain = getCookieDomain(req, res);
    if (res) {
      const cookies = new Cookies(req, res);
      cookies.set("token");
      cookies.set("refreshToken");
      cookies.set("tokenExpiration");
    } else {
      jsCookie.remove("token", { domain });
      jsCookie.remove("refreshToken", { domain });
      jsCookie.remove("tokenExpiration", { domain });
    }
  },
};

export default AuthService;
