import React from "react";
import PropTypes from "prop-types";
import { setCurrentUrl } from "actions/app";
import { setAuthTokens, logOut, setCurrentUser } from "actions/auth";
import Error from "components/core/error-page";
import AuthService from "services/auth";
import { wrapper } from "store";

const asPage = (Cmp, restrictAccess = () => 200) => {
  const WrappedComponent = class extends React.Component {
    constructor(props) {
      super(props);
      this.tokenRefreshTimeout = 0;
    }

    componentDidMount() {
      if (this.props.tokens.refreshToken) {
        this.autoRefreshTokens();
      }
    }

    componentDidUpdate() {
      if (this.props.tokens.refreshToken) {
        this.autoRefreshTokens();
      } else if (!this.props.tokens.refreshToken) {
        clearTimeout(this.tokenRefreshTimeout);
      }
    }

    componentWillUnmount() {
      clearTimeout(this.tokenRefreshTimeout);
    }

    autoRefreshTokens() {
      clearTimeout(this.tokenRefreshTimeout);
      const now = Date.now();
      const expiration = this.props.tokens.tokenExpiration * 1000;
      // refresh the tokens 3min before they expire
      const diff = expiration - now - 3 * 60 * 1000;
      if (diff > 0) {
        this.tokenRefreshTimeout = setTimeout(() => this.updateTokens(), diff);
      } else {
        this.updateTokens();
      }
    }

    updateTokens() {
      const { tokens } = this.props;
      const cookiesTokens = AuthService.getTokensCookies();
      if (cookiesTokens === this.props.tokens) {
        AuthService.getNewTokens(tokens.refreshToken)
          .then((newTokens) => {
            AuthService.setTokensCookies(newTokens);
            this.props.dispatch(setAuthTokens(newTokens));
          })
          .catch(() => {
            AuthService.clearTokensCookies();
            alert(
              "Your session has expired, you will be automatically logged out.",
            );
            window.location.href = "/";
          });
      } else {
        AuthService.setTokensCookies(cookiesTokens);
      }
    }

    render() {
      const { status, ...props } = this.props;
      if (status && status !== 200) {
        return <Error statusCode={status} {...props} />;
      }

      const accessProps = restrictAccess(this.props);
      if (accessProps.status && accessProps.status !== 200) {
        const { status: accessStatus, ...restAccessProps } = accessProps;
        return <Error statusCode={accessStatus} {...restAccessProps} />;
      }

      return <Cmp {...this.props} />;
    }
  };

  WrappedComponent.getInitialProps = wrapper.getInitialPageProps(
    (reduxStore) =>
      async ({ req, res, ...ctx }) => {
        let props = {
          asPath: ctx.asPath,
          query: ctx.query,
          status: 200,
          user: {},
        };
        const currentUrl = req ? req.url : "";

        try {
          const tokens = await AuthService.getTokens(req, res);
          const loggedIn = AuthService.isLoggedIn(req);

          props = { ...props, tokens, loggedIn, store: reduxStore };

          if (loggedIn) {
            try {
              const user = await AuthService.getCurrentUser(tokens.token);
              props = { ...props, user };
              reduxStore.dispatch(setAuthTokens(tokens));
              reduxStore.dispatch(setCurrentUser(user));
              reduxStore.dispatch(setCurrentUrl(currentUrl));

              if (Cmp.getInitialProps) {
                const cmpProps = await Cmp.getInitialProps({
                  req,
                  res,
                  ...ctx,
                  tokens,
                  user,
                  store: reduxStore,
                });
                props = { ...props, ...cmpProps };
              }

              if (props.status === 200) {
                props = { ...props, ...restrictAccess(props) };
              }

              return props;
            } catch (error) {
              console.log("ERROR 1", error);
              // failed to fetch current user
              reduxStore.dispatch(logOut());

              try {
                const newTokens = await AuthService.logOut();
                reduxStore.dispatch(setAuthTokens(newTokens));
                reduxStore.dispatch(setCurrentUrl(currentUrl));
                props = { ...props, tokens: newTokens, store: reduxStore };
                if (Cmp.getInitialProps) {
                  const cmpProps = await Cmp.getInitialProps({
                    req,
                    res,
                    ...ctx,
                    tokens: newTokens,
                    store: reduxStore,
                  });
                  props = { ...props, ...cmpProps };
                }

                if (props.status === 200) {
                  props = { ...props, ...restrictAccess(props) };
                }

                return props;
              } catch (newError) {
                console.log("ERROR 2", newError);
                props.status = newError.code ? newError.code : 500;
                if (newError.message) {
                  props.message = newError.message;
                }

                if (props.status === 200) {
                  props = { ...props, ...restrictAccess(props) };
                }

                return props;
              }
            }
          } else if (Cmp.getInitialProps) {
            reduxStore.dispatch(setAuthTokens(tokens));
            reduxStore.dispatch(setCurrentUrl(currentUrl));
            const cmpProps = await Cmp.getInitialProps({
              req,
              res,
              ...ctx,
              tokens,
              store: reduxStore,
            });

            props = { ...props, ...cmpProps };

            if (props.status === 200) {
              props = { ...props, ...restrictAccess(props) };
            }

            return props;
          } else {
            reduxStore.dispatch(setAuthTokens(tokens));
          }

          return props;
        } catch (error) {
          console.log("ERROR 3", error);
          // failed to get tokens
          reduxStore.dispatch(logOut());

          try {
            const newTokens = await AuthService.logOut();
            reduxStore.dispatch(setAuthTokens(newTokens));
            reduxStore.dispatch(setCurrentUrl(currentUrl));
            props = { ...props, tokens: newTokens, store: reduxStore };
            if (Cmp.getInitialProps) {
              const cmpProps = await Cmp.getInitialProps({
                req,
                res,
                ...ctx,
                tokens: newTokens,
                store: reduxStore,
              });

              props = { ...props, ...cmpProps };
            }

            if (props.status === 200) {
              props = { ...props, ...restrictAccess(props) };
            }

            return props;
          } catch (newError) {
            console.log("ERROR 4", newError);
            props.status = newError.code ? newError.code : 500;
            if (newError.message) {
              props.message = newError.message;
            }

            if (props.status === 200) {
              props = { ...props, ...restrictAccess(props) };
            }

            return props;
          }
        }
      },
  );

  WrappedComponent.displayName = `asPage(${Cmp.name})`;

  WrappedComponent.propTypes = {
    status: PropTypes.number,
    tokens: PropTypes.shape({
      token: PropTypes.string,
      refreshToken: PropTypes.string,
      tokenExpiration: PropTypes.number,
    }),
    dispatch: PropTypes.func,
  };

  WrappedComponent.defaultProps = {
    status: undefined,
    tokens: {
      token: "",
      refreshToken: "",
      tokenExpiration: 0,
    },
    dispatch: () => null,
  };

  return WrappedComponent;
};

export default asPage;
