import React from "react";
import { connect } from "react-redux";

const withAuth = (Cmp) => {
  const WrappedComponent = (props) => <Cmp {...props} />;

  WrappedComponent.displayName = `WithAuth(${Cmp.name})`;

  return connect((state) => ({
    user: state.auth.currentUser,
    tokens: {
      token: state.auth.token,
      refreshToken: state.auth.refreshToken,
      tokenExpiration: state.auth.tokenExpiration,
    },
  }))(WrappedComponent);
};

export default withAuth;
