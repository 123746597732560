import React from "react";
import PropTypes from "prop-types";
import Svg from "components/elements/svg";

const Preloader = ({ icon, show, theme, ...props }) => (
  <div {...props} className={`preloader${show ? "" : " hide"} ${theme}`}>
    <div className="preloader__inner" />
    <div className="preloader__inner-secondary" />
    {icon && <Svg className="icon" src={icon} />}
  </div>
);

Preloader.propTypes = {
  show: PropTypes.bool.isRequired,
  icon: PropTypes.string,
  theme: PropTypes.oneOf(["light", "transparent", "dark"]),
};

Preloader.defaultProps = {
  icon: "",
  theme: "light",
};

export default Preloader;
