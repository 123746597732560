import React from "react";
import { useDispatch } from "react-redux";
import { toggleMenu } from "actions/app";

const MobileMenuOverlay = () => {
  const dispatch = useDispatch();

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className="mobile-menu__overlay"
      role="button"
      tabIndex={0}
      onClick={() => dispatch(toggleMenu())}
      aria-label="Close"
    />
  );
};

export default MobileMenuOverlay;
