import React from "react";
import { NumericFormat } from "react-number-format";
import config from "config";

const prefix = `${config.currency}`;

const FormattedPrice = ({
  price,
  decimals = false,
  className,
}: {
  price: number;
  decimals?: boolean;
  className?: string;
}) => (
  <NumericFormat
    className={className}
    thousandSeparator=","
    prefix={prefix}
    displayType="text"
    allowNegative={false}
    decimalScale={decimals ? 2 : 0}
    value={price}
  />
);

export default FormattedPrice;
