/* eslint-disable global-require */
if (typeof window !== "undefined") {
  require("smoothscroll-polyfill").polyfill();
}

const scrollToElem = (elem) => {
  window.scrollBy({
    top: elem.getBoundingClientRect().top,
    left: 0,
    behavior: "smooth",
  });
};

export default scrollToElem;
