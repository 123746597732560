import React from "react";
import Link from "components/elements/link";
import PhoneLink from "components/elements/phone-link";
import SVG from "components/elements/svg";
import { footerMenu } from "helpers/menus";

const Footer = () => {
  const menu = footerMenu.filter((item) => item.url !== "sell-property");

  return (
    <footer className="footer">
      <div className="footer-pre">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center footer-pre__integrations">
              <ul>
                <li className="footer-pre__integrations__item">
                  <SVG src="tpo" className="icon__tpo" />
                </li>
                <li className="footer-pre__integrations__item">
                  <SVG src="tsi" className="icon__tsi" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-main">
        <div className="container">
          <ul className="footer-main__menu">
            {menu.map((item) => (
              <li key={item.label}>
                <Link to={item.url}>{item.label}</Link>
              </li>
            ))}
          </ul>
          <div className="footer-main__company">
            <div className="row">
              <div className="col-sm-6 col-md-6 footer-main__company--info">
                <div className="row">
                  <div className="col-lg-6">
                    <p>Hammer Price Homes Limited</p>
                    <p>Company Registration No: 08819923</p>
                  </div>
                  <div className="col-lg-6">
                    <p>VAT Registration No: 206&nbsp;1357&nbsp;41</p>
                    <p>MLR Registration No: 12818228</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 footer-main__company--contact">
                <p>
                  <SVG src="phone" /> <PhoneLink />
                </p>
                <p>
                  <SVG src="envelope" />{" "}
                  <a href="mailto:info@hammerpricehomes.co.uk">
                    info@hammerpricehomes.co.uk
                  </a>
                </p>
              </div>
              <div className="col-sm-6 col-md-6 footer-main__company--copy">
                <p>&copy; {new Date().getFullYear()} Hammer Price Homes.</p>
                <p>All rights reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
