const getSelfApiUrl = () => {
  if (typeof window === "undefined") {
    if (process.env.VERCEL_URL && process.env.NODE_ENV === "production") {
      return `https://${process.env.VERCEL_URL}`;
    }

    if (
      process.env.NEXT_PUBLIC_VERCEL_URL &&
      process.env.NODE_ENV === "production"
    ) {
      return `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`;
    }

    return "http://localhost:3000";
  }

  return window.location.origin;
};

const config = {
  property: {
    placeholder: "/static/images/property-placeholder.jpg",
    needFinanceUrl: "#",
    needSolicitorUrl: "#",
  },
  currency: "£",
  timezone: "Europe/London",
  apiUrl: process.env.NEXT_PUBLIC_API_URL,
  selfUrl: getSelfApiUrl(),
  pusherAppKey: process.env.NEXT_PUBLIC_PUSHER_KEY,
};

// eslint-disable-next-line unicorn/prefer-module
module.exports = config;
