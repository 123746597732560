import React from "react";
import debounce from "lodash/debounce";
import Svg from "components/elements/svg";
import scrollToElem from "helpers/scroll-to-elem";

class BackToTop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };

    this.handleScroll = debounce(this.handleScroll.bind(this), 10);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    const doc = document.documentElement;
    const position =
      (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (position > window.innerHeight / 2 && !this.state.show) {
      this.setState({ show: true });
    } else if (this.state.show && position <= window.innerHeight / 2) {
      this.setState({ show: false });
    }
  }

  scrollToTop() {
    scrollToElem(document.body);
  }

  render() {
    const { show } = this.state;
    return (
      <button
        onClick={this.scrollToTop}
        title="Back to top"
        className={`back-to-top ${show ? "show" : ""}`}
      >
        <Svg className="icon" src="chevron-up" />
      </button>
    );
  }
}

export default BackToTop;
