import React from "react";
import clsx from "clsx";
import InlineSvg from "react-inlinesvg";

const Svg = ({
  src,
  className,
  title,
  onClick,
}: {
  src: string;
  className?: string;
  title?: string;
  onClick?: () => void;
}) => {
  return (
    <span
      className={clsx("svg-container", className)}
      aria-label={title}
      onClick={onClick}
      aria-hidden="true"
    >
      <InlineSvg src={`/static/vectors/${src}.svg`} cacheRequests />
    </span>
  );
};

export default Svg;
