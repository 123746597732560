export const mainMenu = [
  { label: "Home", url: "/" },
  {
    label: "Properties",
    url: "properties",
    sub: [
      { label: "Current Auctions", url: "properties?status=publish" },
      { label: "Sold Properties", url: "properties?status=sold" },
    ],
  },
  { label: "Sell property", url: "sell-property" },
  {
    label: "How it works",
    url: "how-it-works",
    sub: [
      { label: "Buyer", url: "how-it-works/buyer" },
      { label: "Seller", url: "how-it-works/seller" },
    ],
  },
  {
    label: "FAQ",
    url: "faq",
    sub: [
      { label: "Buyer", url: "faq/buyer" },
      { label: "Seller", url: "faq/seller" },
      { label: "General", url: "faq/general" },
    ],
  },
  {
    label: "Testimonials",
    url: "testimonials",
    sub: [
      { label: "Buyer", url: "testimonials/buyer" },
      { label: "Seller", url: "testimonials/seller" },
      { label: "Solicitor", url: "testimonials/solicitor" },
    ],
  },
  { label: "Contact", url: "contact" },
  {
    label: "Other Services",
    url: "#",
    sub: [{ label: "Property Management", url: "https://hph-property.co.uk/" }],
  },
];

export const footerMenu = [
  { label: "Home", url: "/" },
  { label: "Properties", url: "properties" },
  { label: "Sell property", url: "sell-property" },
  { label: "How it works", url: "how-it-works" },
  { label: "FAQs", url: "faq" },
  { label: "Testimonials", url: "testimonials" },
  { label: "Terms & conditions", url: "terms-and-conditions" },
  { label: "Privacy policy", url: "privacy-policy" },
  { label: "Contact", url: "contact" },
];

export const mobileMenu = [
  ...mainMenu,
  { label: "Terms & conditions", url: "terms-and-conditions" },
  { label: "Privacy policy", url: "privacy-policy" },
];
