import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAppLoading } from "actions/app";
import { setAuthTokens } from "actions/auth";
import Link from "components/elements/link";
import Svg from "components/elements/svg";
import { USER_TABS } from "components/my-account/tabs";
import { mobileMenu } from "helpers/menus";
import AuthService from "services/auth";
import { AppState } from "store";

const MobileMenu = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: AppState) => state.auth.currentUser);
  const menu = mobileMenu.filter((item) => item.url !== "sell-property");
  const [openSubMenus, setState] = useState<string[]>([]);

  const onLogOut = () => {
    dispatch(setAppLoading());
    AuthService.logOut()
      .then((newTokens) => {
        dispatch(setAuthTokens(newTokens));
        window.location.href = "/sign-in";
      })
      .catch(() => {});
  };

  const toggleSubMenu = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    parentUrl: string,
  ) => {
    const value = [...openSubMenus];

    e.preventDefault();
    if (value.includes(parentUrl)) {
      const index = value.indexOf(parentUrl);
      value.splice(index, 1);
      setState(value);
    } else {
      value.push(parentUrl);
      setState(value);
    }
  };

  return (
    <div className="mobile-menu" onScroll={(e) => e.stopPropagation()}>
      {!user.id && (
        <div className="mobile-menu__account">
          <Link to="sign-in" className="sign-in">
            Sign in
          </Link>
          <Link to="register" className="register">
            Register
          </Link>
        </div>
      )}
      <ul className="mobile-menu__menu">
        {user.id && (
          <li
            className={`menu-item has-submenu ${
              openSubMenus.includes("my-account") ? " open" : ""
            }`}
          >
            <button
              onClick={(e) => toggleSubMenu(e, "my-account")}
              type="button"
            >
              My account
              <Svg className="icon" src="chevron-down" />
            </button>
            <ul className="menu-item__sub">
              {USER_TABS(user).map((subMenuItem) => (
                <li className="sub-menu-item" key={subMenuItem.name}>
                  <Link to={subMenuItem.link}>
                    {subMenuItem.name}
                    {subMenuItem.link === "my-account/notifications" && (
                      <span> ({user.notificationCount})</span>
                    )}
                  </Link>
                </li>
              ))}
            </ul>
          </li>
        )}
        {menu.map((menuItem) => (
          <li
            className={`menu-item${menuItem.sub ? " has-submenu" : ""}${
              openSubMenus.includes(menuItem.url) ? " open" : ""
            }`}
            key={menuItem.label}
          >
            {!menuItem.sub && <Link to={menuItem.url}>{menuItem.label}</Link>}
            {menuItem.sub && (
              <button
                onClick={
                  menuItem.sub
                    ? (e) => toggleSubMenu(e, menuItem.url)
                    : undefined
                }
                type="button"
              >
                {menuItem.label}
                <Svg className="icon" src="chevron-down" />
              </button>
            )}
            {menuItem.sub && (
              <ul className="menu-item__sub">
                {menuItem.sub.map((subMenuItem) => (
                  <li className="sub-menu-item" key={subMenuItem.label}>
                    <Link to={subMenuItem.url}>{subMenuItem.label}</Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
      {user.id && (
        <div className="mobile-menu__account">
          <button onClick={() => onLogOut()} className="register" type="button">
            Log out
          </button>
        </div>
      )}
    </div>
  );
};

export default MobileMenu;
