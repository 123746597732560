const parseAxiosRejectError = (error) => {
  let errorMessage = "";
  let status;
  let data = {};
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    if (error.response.status) {
      status = error.response.status;
    }
    if (error.response.data) {
      data = error.response.data;
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest
    status = error.request.status;
  }
  if (!status) {
    status = 500;
  }
  console.error({ ...error });
  if (!errorMessage) {
    switch (status) {
      case 401: {
        errorMessage = "Not authorized";
        break;
      }
      case 404: {
        errorMessage = "Not found";
        break;
      }
      case 500: {
        errorMessage = "Internal server error";
        break;
      }
      default: {
        errorMessage = "Something went wrong";
        break;
      }
    }
  }
  return {
    code: status,
    message: data.message || errorMessage,
    data,
  };
};

export default parseAxiosRejectError;
